@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(./Inter-Regular.woff2), url(./Inter-Regular.woff);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url(./Inter-Medium.woff2), url(./Inter-Medium.woff);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(./Inter-Bold.woff2), url(./Inter-Bold.woff);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url(./Inter-Black.woff2), url(./Inter-Black.woff);
  font-display: swap;
}
