.popup__button {
  width: 100%;
  background-color: #000;
  height: 50px;
  line-height: 22px;
  font-size: 18px;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.popup__button:hover {
  opacity: 0.6;
}

.popup__button:disabled {
  background-color: #fff;
  border: 1px solid rgb(0, 0, 0, 0.2);
  color: rgb(0, 0, 0, 0.2);
}

@media screen and (max-width: 640px) {
  .popup__button {
    font-size: 14px;
    line-height: 17px;
  }
}
