.popup__field {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
}

.popup__field:focus {
  outline: none;
}

@media screen and (max-width: 640px) {
  .popup__field {
    font-size: 12px;
    line-height: 15px;
  }
}
