.form__field {
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border: none;
  border-bottom: 2px solid #ccc;
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
  background-color: transparent;
}

.form__field:focus {
  outline: none;
}

@media screen and (max-width: 640px) {
  .form__field {
    font-size: 12px;
    line-height: 15px;
  }
}
