.photo-grid__title {
  color: #000;
  margin: 0;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  white-space: nowrap;
}
