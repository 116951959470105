@import url('./__text/footer__text.css');

.footer {
  padding: 45px 0 60px;
}

@media screen and (max-width: 624px) {
  .footer {
    padding: 28px 0 36px;
  }
}
