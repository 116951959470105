.profile__edit-button {
  background-image: url('../../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 24px;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  background-color: #000;
  position: absolute;
  top: 19px;
  right: -52px;
  cursor: pointer;
}

.profile__edit-button:hover {
  opacity: 0.6;
}

.profile__edit-button:focus {
  outline: none;
}

@media screen and (max-width: 640px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    background-size: 7.5px 7.5px;
    right: -25px;
  }
}
