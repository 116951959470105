.form__fields-container {
  padding: 24px 0 48px;
  margin: 0;
  border: none;
}

@media screen and (max-width: 640px) {
  .form__fields-container {
    padding: 23px 0 19px;
  }
}
