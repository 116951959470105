.form__heading {
  color: #fff;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .form__heading {
    font-size: 18px;
    line-height: 22px;
  }
}
