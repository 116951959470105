.profile__add-button {
  background-image: url('../../../images/add.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  border: 2px solid #fff;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.profile__add-button:hover {
  opacity: 0.6;
}

.profile__add-button:focus {
  outline: none;
}

@media screen and (max-width: 840px) {
  .profile__add-button {
    width: 100%;
    max-width: 282px;
  }
}

@media screen and (max-width: 640px) {
  .profile__add-button {
    background-size: 16px 16px;
  }
}
