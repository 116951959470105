.profile__avatar-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 30px;
}

@media screen and (max-width: 640px) {
  .profile__avatar-container {
    margin-right: 0px;
  }
}
