.profile__user-avatar_overlay {
  background-image: url('../../../../images/avatar-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.1s ease-in;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  cursor: pointer;
}

.profile__user-avatar_overlay:hover {
  opacity: 1;
  z-index: 10;
}

.profile__user-avatar_overlay:focus {
  outline: none;
}
