.popup__close-button {
  background-image: url('../../../images/close-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -40px;
  right: -40px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.popup__close-button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 640px) {
  .popup__close-button {
    background-size: 30px 30px;
    top: -30px;
    right: -30px;
  }
}
