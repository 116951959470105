.popup__heading {
  color: #000;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  margin: 0;
}

@media screen and (max-width: 640px) {
  .popup__heading {
    font-size: 18px;
    line-height: 22px;
  }
}
