.profile__user-name {
  margin: 0;
  font-size: 42px;
  font-weight: 500;
  line-height: 56px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 640px) {
  .profile__user-name {
    font-size: 22px;
    line-height: 56px;
    max-width: 250px;
  }
}
