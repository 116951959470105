.profile__user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 840px) {
  .profile__user-container {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 640px) {
  .profile__user-container {
    flex-direction: column;
  }
}
